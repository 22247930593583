<template>
  <div class="main">
    <!-- 头部 -->
    <tobs ref="citynews"></tobs>

      <!-- <img class="bgc1" src="@/assets/img/bgc3.png" alt="" />
      <img class="bgc2" src="@/assets/img/bgc4.png" alt="" /> -->
      <div class="beititles"><img src="../../img/batter.png" alt="" /></div>

    <div class="center c">
      <div class="c-left">
        <div class="c-l-box">
          <div class="title">
            <div class="titleName">
              <i class="iconfont icon-diannao" style="font-size: 18px"></i>
              <span>平台资讯</span>
            </div>
            <div class="more">
              <span @click="toColumnlist('平台资讯')">更多</span>
            </div>
          </div>
          <div class="content">
            <div class="imgNews">
              <template v-for="(item, index) in realTimeInfoList">
                <div
                  class="bigImg"
                  :key="index"
                  v-if="index == 0"
                  @click="toContent(item)"
                >
                  <img  :src="$imgUrl(item.litimg)" alt="" />
                  <div class="imgText">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </template>
              <div class="smallImgs">
                <template v-for="(item, index) in realTimeInfoList">
                  <div
                    class="smallImg"
                    :key="index"
                    v-if="index == 1 || index == 2"
                    @click="toContent(item)"
                  >
                    <img  :src="$imgUrl(item.litimg)" alt="" />
                    <div class="imgText">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="textNews">
              <template v-for="(item, index) in realTimeInfoList">
                <div
                  class="textItem"
                  v-if="index == 3 || index == 4 || index == 5"
                  :key="index"
                  @click="toContent(item)"
                >
                  <p>{{ item.title }}</p>
                  <span>{{ item.intro }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="c-l-box">
          <div class="title">
            <div class="titleName">
              <i
                class="iconfont icon-shanchanghangye"
                style="font-size: 26px"
              ></i>
              <span>行业动态</span>
            </div>
            <div class="more">
              <span @click="toColumnlist('行业动态')">更多</span>
            </div>
          </div>
          <div class="content">
            <div class="imgNews">
              <template v-for="(item, index) in industryTrendsList">
                <div
                  class="bigImg"
                  :key="index"
                  v-if="index == 0"
                  @click="toContent(item)"
                >
                  <img  :src="$imgUrl(item.litimg)" alt="" />
                  <div class="imgText">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </template>
              <div class="smallImgs">
                <template v-for="(item, index) in industryTrendsList">
                  <div
                    class="smallImg"
                    :key="index"
                    v-if="index == 1 || index == 2"
                    @click="toContent(item)"
                  >
                    <img  :src="$imgUrl(item.litimg)"  alt="" />
                    <div class="imgText">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="textNews">
              <template v-for="(item, index) in industryTrendsList">
                <div
                  class="textItem"
                  :key="index"
                  v-if="index == 3 || index == 4 || index == 5"
                  @click="toContent(item)"
                >
                  <p>{{ item.title }}</p>
                  <span>{{ item.intro }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="c-l-box">
          <div class="title">
            <div class="titleName">
              <i class="iconfont icon-jiaoyupeixun" style="font-size: 22px"></i>
              <span>培训动态</span>
            </div>
            <div class="more">
              <span @click="toColumnlist('培训动态')">更多</span>
            </div>
          </div>
          <div class="content">
            <div class="imgNews">
              <template v-for="(item, index) in trainingTrendsList">
                <div
                  class="bigImg"
                  :key="index"
                  v-if="index == 0"
                  @click="toContent(item)"
                >
                  <img  :src="$imgUrl(item.litimg)"   alt="" />
                  <div class="imgText">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </template>
              <div class="smallImgs">
                <template v-for="(item, index) in trainingTrendsList">
                  <div
                    class="smallImg"
                    :key="index"
                    v-if="index == 1 || index == 2"
                    @click="toContent(item)"
                  >
                    <img  :src="$imgUrl(item.litimg)"   alt="" />
                    <div class="imgText">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="textNews">
              <template v-for="(item, index) in trainingTrendsList">
                <div
                  class="textItem"
                  :key="index"
                  v-if="index == 3 || index == 4 || index == 5"
                  @click="toContent(item)"
                >
                  <p>{{ item.title }}</p>
                  <span>{{ item.intro }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="c-right">
        <div class="topBox">
          <div class="title title2">
            <div class="titleName">
              <i class="iconfont icon-zhengcefagui-m"></i>
              <span class="columnName">政策法规</span>
            </div>
            <div class="more">
              <span @click="toColumnlist('政策法规')">更多</span>
            </div>
          </div>
          <div class="news">
            <template v-for="(item, index) in policyList">
              <div
                class="imgItem"
                :key="index"
                v-if="index == 0 || index == 1 || index == 2"
                @click="toContent(item)"
              >
                <img  :src="$imgUrl(item.litimg)"   alt="" />
                <div class="imgText">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </template>
            <ul>
              <template v-for="(item, index) in policyList">
                <li
                  :key="index"
                  v-if="index == 3 || index == 4 || index == 5 || index == 6"
                  @click="toContent(item)"
                >
                  <i></i>
                  <span>{{ item.title }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="bottomBox">
          <div class="bottomItem">
            <div class="title title2">
              <div class="titleName">
                <span class="columnName blueBottom">新视野</span>
              </div>
              <div class="more">
                <span @click="toColumnlist('新视野')">更多</span>
              </div>
            </div>
            <div
              class="smallImgItem"
              v-for="(item, index) in newHorizonsList"
              :key="index"
              @click="toContent(item)"
            >
              <img v-if="item.newsType == '2'" :src="$imgUrl(item.litimg)"  alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="bottomItem">
            <div class="title title2">
              <div class="titleName">
                <span class="columnName blueBottom">新工艺</span>
              </div>
              <div class="more">
                <span @click="toColumnlist('新工艺')">更多</span>
              </div>
            </div>
            <div
              class="smallImgItem"
              v-for="(item, index) in newProcessList"
              :key="index"
              @click="toContent(item)"
            >
              <img v-if="item.newsType == '2'" :src="$imgUrl(item.litimg)"  alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="bottomItem">
            <div class="title title2">
              <div class="titleName">
                <span class="columnName blueBottom">新材料</span>
              </div>
              <div class="more">
                <span @click="toColumnlist('新材料')">更多</span>
              </div>
            </div>
            <div
              class="smallImgItem"
              v-for="(item, index) in newMaterialsList"
              :key="index"
              @click="toContent(item)"
            >
              <img v-if="item.newsType == '2'" :src="$imgUrl(item.litimg)" alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="bottomItem">
            <div class="title title2">
              <div class="titleName">
                <span class="columnName blueBottom">新技术</span>
              </div>
              <div class="more">
                <span @click="toColumnlist('新技术')">更多</span>
              </div>
            </div>
            <div
              class="smallImgItem"
              v-for="(item, index) in newTechniqueList"
              :key="index"
              @click="toContent(item)"
            >
              <img v-if="item.newsType == '2'" :src="$imgUrl(item.litimg)"  alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foots style="margin-top: 50px"></foots>
  </div>
</template>

<script>
import tobs from "@/components/hometitle/hometitle.vue";
import foots from "@/components/foot/foot.vue";
import { jobNewsColumnTree, jobNews } from "@/api/home/home";
export default {
  name: "",
  data() {
    return {
      // imgUrl: "",
      columnList: [], //所有新闻栏目列表
      columnData: [], //指定新闻栏目列表
      realTimeInfoList: [], //平台资讯
      industryTrendsList: [], //行业动态
      trainingTrendsList: [], //培训动态
      policyList: [], //政策法规
      newHorizonsList: [], //新视野
      newProcessList: [], //新工艺
      newMaterialsList: [], //新材料
      newTechniqueList: [], //新技术
    };
  },
  watch: {},
  props: {},
  components: {
    tobs,
    foots,
  },
  computed: {},
  created() {
    this.getJobNewsColumnTree();
  },
  mounted() {},
  methods: {
    // 查询新闻栏目
    getJobNewsColumnTree() {
      jobNewsColumnTree().then((res) => {
        this.columnList = res.data[0].children;
        let columnData = [];
        this.columnList.filter((i) => {
          if (
            i.title == "平台资讯" ||
            i.title == "行业动态" ||
            i.title == "培训动态" ||
            i.title == "政策法规" ||
            i.title == "新视野" ||
            i.title == "新工艺" ||
            i.title == "新材料" ||
            i.title == "新技术"
          ) {
            columnData.push(i);
          }
        });
        this.columnData = columnData;

        columnData.forEach((i) => {
          if (i.title == "平台资讯") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.realTimeInfoList = res.data.rows.splice(0, 6);
            });
          }
          if (i.title == "行业动态") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.industryTrendsList = res.data.rows.splice(0, 6);
            });
          }
          if (i.title == "培训动态") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.trainingTrendsList = res.data.rows.splice(0, 6);
            });
          }
          if (i.title == "政策法规") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.policyList = res.data.rows.splice(0, 7);
            });
          }
          if (i.title == "新视野") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.newHorizonsList = res.data.rows.splice(0, 3);
            });
          }
          if (i.title == "新工艺") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.newProcessList = res.data.rows.splice(0, 3);
            });
          }
          if (i.title == "新材料") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.newMaterialsList = res.data.rows.splice(0, 3);
            });
          }
          if (i.title == "新技术") {
            jobNews({ jobNewsColumnId: i.id }).then((res) => {
              this.newTechniqueList = res.data.rows.splice(0, 3);
            });
          }
        });
      });
    },
    toContent(item) {
      if(item.newsType == '1' && item.target){
        window.location.href = item.target;
      }else {
        this.$router.push({
            path: "/content",
            query: { id: item.id }
      })
      }

    },
    toColumnlist(str) {
      console.log(this.columnData);
      let id = this.columnData.filter((i) => i.title == str)[0].id;
      this.$router.push({
           path: "/columnlist",
           query: { id: id }
      })
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}

  .beititles {
    margin-top: 80px;
    width: 100%;
    height: 220px;
    img {
      width: 100%;
      height: 100%;
      display: block;
  }
}

.center {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  .c-left {
    width: 881px;
  }
  .c-right {
    width: 303px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  .titleName {
    color: #029aff;
    display: flex;
    align-items: center;
    width: 200px;
    i {
      margin-right: 14px;
    }
    span {
      font-size: 20px;
    }
  }
  .more {
    flex: 1;
    color: #999;
    font-size: 12px;
    text-align: right;
    position: relative;
    span {
      cursor: pointer;
    }
  }
  .more::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #eee;
    right: -20px;
    bottom: -12px;
    width: 100%;
  }
}

.topBox,
.bottomItem {
  .title2 {
    border-bottom: 1px solid #eee;
    .columnName {
      font-size: 16px;
    }
    .more::after {
      display: none;
    }
  }
}

.imgText {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);

  span {
    color: #fff;
    margin-left: 10px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.c-l-box {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  .content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .imgNews {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .bigImg {
        width: 485px;
        height: 320px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .imgText {
          height: 47px;
          line-height: 47px;
        }
      }
      .smallImgs {
        display: flex;
        justify-content: space-between;
        width: 485px;
        height: 156px;
        .smallImg {
          width: 230px;
          height: 100%;
          position: relative;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .imgText {
            height: 38px;
            line-height: 38px;
          }
        }
      }
    }
    .textNews {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      .textItem {
        padding: 10px 0;
        height: 33.33%;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        p {
          font-size: 18px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        span {
          font-size: 14px;
          color: #666;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .textItem:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}

.c-right {
  .topBox {
    background-color: #fff;
    margin-bottom: 20px;
    .news {
      padding: 10px 20px 20px 20px;
      .imgItem {
        width: 100%;
        height: 146px;
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .imgText {
          height: 38px;
          line-height: 38px;
        }
      }
    }
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        padding: 5px 0;
        color: #666;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #ccc;
          margin-right: 6px;
        }
        span {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        span:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .bottomBox {
    background-color: #fff;
    padding-bottom: 20px;
    .smallImgItem {
      display: flex;
      align-items: center;
      padding: 0px 20px;
      margin: 15px 0;
      cursor: pointer;
      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        object-fit: cover;
      }
      span {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.blueBottom {
  position: relative;
}
.blueBottom::after {
  position: absolute;
  content: "";
  width: 28px;
  height: 2px;
  background-color: #029aff;
  left: 0;
  bottom: -10px;
}
</style>
